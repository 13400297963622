html, body,#root {
  width: 100vw;
  /*min-width: 100vw;*/
  max-width: 100vw;
  overflow-x: hidden;
  /*height: 100vh;*/
  min-height: 100vh;
  /*max-height: 100vh;*/
  /*overflow-y: scroll;*/
  
  position: relative;
  text-align: center;
  display: flex;
   justify-content: center;
   flex-direction: row;
}

.billOfLading{
  z-index:1;
  position:fixed;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%,50%);
  height:100%;
  width:1200px;
  background-color:white;
  padding:40px;
  /*opacity: 0;*/
}


.red {
  background-color: #fa5855;
}

.App {
  text-align: center;
  /*overflow: hidden;*/
  width: 100%;
  height: 100%;
  /*min-width: 100vw;*/
  max-width: 1500px;
  overflow: hidden;

}
.yup {

  width: 100%;
  height: 100%;
  /*min-width: 100vw;*/
  max-width: 1500px;
  overflow: hidden;
}

/*.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/

.cartQty {
/*  font-size: 20;*/
/*  color:green;*/
}

.menuClassName {
  /*display: flex;*/
/*  border: 1px black solid;*/
  /*align-items: center;*/
  /*display: flex;*/
  max-height: 300px;
  z-index: 1;
  position: absolute;
  overflow-y: scroll;
  background-color: white;
  padding:10px;
/*  width:100%;*/
}
.dropdown {
/*  flex: 1;*/
  /*flex: 8;*/
  /*display: flex;*/
  /*border: 1px black solid;*/
/*  overflow: hidden;*/
/*  border: 1px blue solid;*/
  /*align-items: center;*/
  /*display: flex;*/
/*  overflow: hidden;*/
  z-index: 1;
  position: relative;
  background-color: white;
}
.dropdownC {
/*  border: 1px green solid;*/
  padding: 10px;
  display: flex;
  flex: 1;
/*  z-index: 101;*/
  position: relative;
  background-color: white;
}
.fuck {
  /*margin: auto;*/
  border-bottom: 1px black solid;
}
/*.arrow-closed {
  margin: auto;
}
.arrow-open {
  margin: auto;
}*/
.fuck1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav1 {
   overflow-x: hidden;
   z-index: 100;
}

.link{
  color: black;
  text-decoration: none;

  padding-right: 5px;

}
.link:hover{
  color: grey;

}

.stickPos{
  width: 100%;
  max-width: 1600px;
  position: fixed;
  z-index: 100;
  margin-right: 10px;
}

.sticky {
  /*top: 0;*/
  width: 100%;
  /*transform: translateY(100%) 2s;*/
  animation-name: animaTop;
  animation-duration: .25s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  /*overflow-x: ;*/
}

.navbar {
  background-color: white;
}

nav .navbar-collapse {
  /*position: fixed;*/
  top: 56px;
  /*background: #343a40;*/
  /*right: -100%;*/
  /*width: 100vw;*/
  /*height: 100vh;*/
  /*display: block;*/
  /*transition: bottom 0.3s ease;*/
  text-align: right;
  padding-right: 10px;
  justify-content: right;
}

nav .navbar-collapse.show {
  /*transition: bottom 0.3s ease;*/
  right: 0;
  text-align: right;
  padding-right: 10px;
}

:root{
  --stickyHeight: 56px;
  --stickyHeightMin: -56px;
  --buttonsHeightMin: -66px;
  --buttonsHeight: 66px;
}

.App-header {
  z-index: -10;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}


form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}


#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}